<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <img src="img/theme/logo.png" alt="SicurIA" class="logo" />
          </div>
          <form role="form" v-on:submit.prevent="loginForm">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Username"
              addon-left-icon="ni ni-email-83"
              v-model="username"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
            >
            </base-input>
            <div class="text-center">
              <base-button type="primary" nativeType="submit" class="my-4">
                Accedi
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from '../api/index';

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    loginForm() {
      this.$axios.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('token');
      const formData = {
        username: this.username,
        password: this.password
      };
      this.$axios
        .post(BASE_URL + '/v1/token/login/', formData)
        .then((res) => {
          const token = res.data.auth_token;
          this.$store.commit('setToken', token);
          this.$axios.defaults.headers.common['Authorization'] =
            'Token ' + token;
          localStorage.setItem('token', token);
          this.$axios.get(BASE_URL + '/v1/users/me').then((res) => {
            this.$store.commit('setUser', res.data.username);
          });
          this.$router.push({ path: '/dashboard' });
        })
        .catch(() => {})
        .finally(() => {});
    }
  }
};
</script>
<style scoped>
.logo {
  width: 65%;
  margin: 1.5rem 0;
}
</style>
