import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import ArgonDashboard from './plugins/argon-dashboard';
import 'element-plus/lib/theme-chalk/index.css';
import './assets/scss/searchbar.scss';

import axios from 'axios';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.config.globalProperties.$axios = axios;
appInstance.mount('#app');
