import { createStore } from 'vuex';
import data from './data';

export default createStore({
  state: {
    user: null,
    token: '',
    isAuthenticated: false
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        this.commit('setToken', localStorage.getItem('token'));
      } else {
        this.commit('removeToken');
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = '';
      state.isAuthenticated = false;
    }
  },
  actions: {},
  getters: {
    user: (state) => state.user
  },
  modules: {
    data
  }
});
