import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import DashboardLayout from '@/layout/DashboardLayout';
import AuthLayout from '@/layout/AuthLayout';
import Login from '../views/Login.vue';
import Dashboard1 from '../views/Dashboard1.vue';
import Dashboard2 from '../views/Dashboard2.vue';
import Dashboard3 from '../views/Dashboard3.vue';

const routes = [
  {
    path: '/',
    redirect: '/dashboard1'
  },
  {
    path: '/dashboards1',
    component: DashboardLayout,
    meta: { requiresLogin: true },
    children: [
      {
        path: '/dashboard1',
        name: 'Indici di Sicurezza',
        components: { default: Dashboard1 }
      },
      {
        path: '/dashboard2',
        name: "Percezione dell'Arma",
        components: { default: Dashboard2 },
        meta: { deniedFor: 'demo' }
      },
      {
        path: '/dashboard3',
        name: 'Scenari',
        components: { default: Dashboard3 }
      }
    ]
  },
  {
    path: '/login',
    component: AuthLayout,
    meta: { skipIfLogged: true },
    children: [
      {
        path: '/login',
        name: 'login',
        components: { default: Login }
      }
    ]
  },
  { path: '/:catchAll(.*)', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresLogin) &&
    store.state.token.length === 0
  ) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else if (
    to.matched.some((record) => record.meta.skipIfLogged) &&
    store.state.token.length !== 0
  ) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    });
  } else if (
    !to.matched.some((record) => record.meta.deniedFor) &&
    store.getters.user === to.meta.deniedFor
  ) {
    next('/');
  } else {
    next();
  }
});

export default router;
