<template>
  <base-header type="dark" class="pt-5 pt-md-6">
    <div class="row">
      <div class="col-xl-12">
        <h1 class="title pb-2" style="color: white">{{ $route.name }}</h1>
        <div class="card">
          <div class="card-body py-3 searchbar">
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Regione</h6>
              <select v-model="form.region">
                <option value="lazio">Lazio</option>
                <option value="lombardia">Lombardia</option>
                <option value="puglia">Puglia</option>
              </select>
            </div>
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Arma</h6>
              <select v-model="form.corpo_arma">
                <option value="PS">Polizia di Stato</option>
                <option value="GDF">Guardia di Finanza</option>
                <option value="PP">Polizia Penitenziaria</option>
              </select>
            </div>
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Frequenza</h6>
              <select v-model="form.frequency">
                <option value="year">Annuale</option>
                <option value="month">Mensile</option>
              </select>
            </div>
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Periodo</h6>
              <DatePicker
                class="select"
                v-model:start-date="form.start_date"
                v-model:end-date="form.end_date"
              />
            </div>
            <base-button
              class="ml-auto search-submit"
              type="primary"
              size="md"
              v-on:click="searchProducts"
              :disabled="loading"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <template v-else>Cerca</template>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </base-header>
</template>
<script>
import { BASE_URL } from '../../api/index';
import DatePicker from '../DatePicker.vue';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      loading: false,
      form: {
        start_date: '2024-01-01',
        end_date: '2024-06-01',
        region: 'lombardia',
        corpo_arma: 'PS',
        frequency: 'month'
      }
    };
  },
  methods: {
    searchProducts() {
      this.loading = true;
      const { region, corpo_arma, frequency, start_date, end_date } = this.form;
      const regione = [region];
      const data = JSON.stringify({
        start_date,
        end_date,
        regione,
        frequency,
        corpo_arma
      });
      this.$axios
        .post(BASE_URL + '/v1/search2/', data)
        .then((res) => {
          this.$store.commit('setData2', res.data);
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.searchProducts();
  }
};
</script>
