<template>
  <div id="map"></div>
</template>
<script>
import data from '../store/data';
import * as L from 'leaflet';
import 'leaflet.heat';
import 'leaflet-toolbar';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet.fullscreen';
import 'leaflet/dist/leaflet.css';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: 'Map',

  map: null,
  tiles: {},
  cluster: null,
  heatmap: null,
  geojson: null,

  data() {
    return {
      data: data.state,
      mapOptions: {
        zoom: 5,
        center: [42, 12],
        zoomSnap: 1,
        zoomDelta: 1,
        zoomControl: false,
        preferCanvas: true,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'bottomright'
        }
      },

      geojsonOptions: {
        weight: 1,
        opacity: 0.4,
        color: '#000',
        fillColor: '#000',
        fillOpacity: 0.05
      },

      tileProviders: [
        {
          name: 'Street',
          visible: false,
          attribution:
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
          url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png'
        },
        {
          name: 'Grayscale',
          visible: true,
          attribution:
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
          url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png'
        },
        {
          name: 'Darkmode',
          visible: false,
          attribution:
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
          url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
        }
      ]
    };
  },

  methods: {
    formatDates(date) {
      return date
        ? date.toLocaleDateString(['it-IT'], {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        : '';
    },
    createMap() {
      if (this.$options.map != null) return;

      // Create map
      this.$options.map = L.map('map', this.mapOptions);

      // Create tile layers
      for (const tile of this.tileProviders) {
        this.$options.tiles[tile.name] = L.tileLayer(tile.url, {
          attribution: tile.attribution
        });
        if (tile.visible)
          this.$options.tiles[tile.name].addTo(this.$options.map);
      }

      // Create marker cluster layer
      this.$options.cluster = L.markerClusterGroup({
        removeOutsideVisibleBounds: true,
        chunkedLoading: true
      }).addTo(this.$options.map);

      // Create heatmap layer
      this.$options.heatmap = L.heatLayer([], { radius: 40 });

      // Create geojson layer
      this.$options.geojson = L.geoJSON([], {
        style: this.geojsonOptions
      }).addTo(this.$options.map);

      // Add layers to map
      const layers = {
        Crimini: this.$options.cluster,
        Heatmap: this.$options.heatmap,
        Quartieri: this.$options.geojson
      };
      L.control
        .layers(this.$options.tiles, layers, { position: 'bottomright' })
        .addTo(this.$options.map);
      L.control.zoom({ position: 'bottomright' }).addTo(this.$options.map);

      this.updateEvents(data.state.dashboard1.events);
    },
    updateEvents(crimes) {
      // Reset all layers
      this.$options.cluster.clearLayers();
      this.$options.heatmap.setLatLngs([]);

      if (!crimes) return;

      // Add markers
      const markers = [];
      crimes.forEach((crime) => {
        if (crime.coords == undefined) return;
        let category = crime.category || 'SCONOSCIUTO';
        const icon = L.icon({
          iconUrl: require('@/assets/icons/categories/' + category + '.svg'),
          iconSize: [30, 30],
          iconAnchor: [15, 15],
          popupAnchor: [0, -5]
        });
        let popup;
        category = crime.subcategory || category;
        if (category != 'SCONOSCIUTO') {
          popup = '<b>' + category.toUpperCase() + '</b><br/>';
          if (crime.date) {
            popup +=
              '<i>' + this.formatDates(new Date(crime.date)) + '</i><br/>';
          }
          popup += crime.address || 'Indirizzo Non Disponibile';
        } else {
          // Crimine senza dettagli
        }
        const coords = [crime.coords.lat, crime.coords.lon];
        this.$options.cluster.addLayer(
          L.marker(coords, {
            icon: icon
          }).bindPopup(popup)
        );
        markers.push(coords);
      });
      this.$options.heatmap.setLatLngs(markers);
    },
    updatePlace(place) {
      this.$options.geojson.clearLayers();
      if (!place) return;
      try {
        const geojson = require('@/assets/files/' + String(place).toLowerCase() + '.json');
        this.$options.geojson.addData(geojson);
        this.$options.map.fitBounds(this.$options.geojson.getBounds());
      } catch {
        console.log("No geojson to display")
      }
    }
  },

  watch: {
    '$store.getters.events': {
      handler: function(events) {
        this.updateEvents(events);
      },
      deep: true
    },
    '$store.getters.place': {
      handler: function(place) {
        this.updatePlace(place);
      }
    }
  },

  updated() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 50);
  },

  mounted() {
    this.createMap();
  },

  unmounted() {
    this.$options.map.remove();
    this.$options.map = null;
  }
};
</script>

<style>
#map {
  height: 100vh;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.leaflet-touch .leaflet-bar a {
  padding: 3px;
  border: none;
  box-sizing: content-box;
  position: relative;
}

.leaflet-touch .leaflet-bar a:not(:last-child)::after {
  content: '';
  width: 70%;
  height: 1px;
  left: 15%;
  bottom: 0;
  background-color: #ddd;
  position: absolute;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-size: 22px;
}

.leaflet-control-layers-list {
  padding: 0 5px;
}

.leaflet-touch .fullscreen-icon {
  background-position: 50% 5px;
}

.leaflet-touch .fullscreen-icon.leaflet-fullscreen-on {
  background-position: 50% -21px;
}
</style>
