<template>
  <div>
    <!--Searchbar-->
    <Search2 />
    <base-header type="dark" class="pb-6 pb-8 pt-5"></base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row mb-5">
        <div class="col-12 col-lg-6 mb-4">
          <card>
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Consapevolezza dell'Arma
                  </h6>
                  <h5 class="h3 mb-0">
                    Quanto se ne parla in Generale
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext">
                        Misura di quanto si parla dei Carabinieri. Varia tra -1
                        e +1. Il valore +1 indica che tutte le volte nelle quali
                        si parla delle Forze dell’Ordine l’Arma è menzionata. Il
                        valore -1 indica invece che nessun articolo che menziona
                        le Forze dell’Ordine nomina i Carabinieri
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="awarenessChartID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <card>
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Consapevolezza dell'Arma
                  </h6>
                  <h5 class="h3 mb-0">
                    Rispetto alle altre Forze di Polizia
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext"
                        >Misura quanto si parla dell’Arma rispetto a una delle
                        altre forze di polizia. varia tra -1 e +1. Un valore
                        pari a +1 indica che si parla solo dell’Arma e niente
                        della Forza di Polizia scelta
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="competitiveChartID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-12">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Consapevolezza dell'Arma
                  </h6>
                  <h5 class="h3 text-white mb-0">Andamento Temporale</h5>
                </div>
                <div class="col">
                  <ul class="nav nav-pills justify-content-end">
                    <li
                      class="nav-item mr-2 mr-md-0"
                      v-if="
                        this.data.dates &&
                        this.data.dates['Competitive Awareness']
                      "
                    >
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{
                          active: biglineChartId.activeIndex === 0
                        }"
                        @click.prevent="initBigLineChart(0)"
                      >
                        <span class="d-none d-md-block">Generale</span>
                        <span class="d-md-none">Generale</span>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        this.data.dates &&
                        this.data.dates['Consapevolezza Brand']
                      "
                    >
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{
                          active: biglineChartId.activeIndex === 1
                        }"
                        @click.prevent="initBigLineChart(1)"
                      >
                        <span class="d-none d-md-block">Altre Forze</span>
                        <span class="d-md-none">Altre</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 350px">
              <canvas :id="biglineChartId.id"></canvas>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--20">
      <div class="row">
        <div class="col-12 col-lg-6 mb-4">
          <card>
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Sentiment sull'Arma
                  </h6>
                  <h5 class="h3 mb-0">
                    Articoli Positivi e Negativi
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext">
                        Misura il sentimento sull’Arma. Varia tra -1 e + 1. Un
                        valore pari a -1 indica che tutti gli articoli nei quali
                        si parla di Carabinieri hanno un sentiment
                        complessivamente negativo. Un valore pari a +1 segnala
                        che tutti gli articoli nei quali si parla dell’Arma sono
                        associati a termini che hanno un’accezione positiva
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="sentimentChartID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <card>
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Sentiment sull'Arma
                  </h6>
                  <h5 class="h3 mb-0">
                    Rispetto alle altre Forze di Polizia
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext">
                        Misura il sentiment dell’Arma rispetto a quello
                        dell’altra Forza di Polizia considerata. Varia tra -1 e
                        +1. Un valore pari a +1 indica la massima distanza nella
                        percezione dell’Arma rispetto alla Forza dell’Ordine
                        considerata, nel senso che dei Carabinieri si parla
                        sempre bene e dell’altra Forza no. Un valore pari a -1
                        indica la situazione opposta
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="sentimentVersusChartID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-12">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Sentiment sull'Arma
                  </h6>
                  <h5 class="h3 text-white mb-0">Andamento Temporale</h5>
                </div>
                <div class="col">
                  <ul class="nav nav-pills justify-content-end">
                    <li
                      class="nav-item mr-2 mr-md-0"
                      v-if="this.data.dates?.SentimentCC"
                    >
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{
                          active: bigbarChartId.activeIndex === 0
                        }"
                        @click.prevent="initBigBarChart(0)"
                      >
                        <span class="d-none d-md-block">Arma</span>
                        <span class="d-md-none">Arma</span>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      v-if="this.data.dates?.SentimentVersus"
                    >
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{
                          active: bigbarChartId.activeIndex === 1
                        }"
                        @click.prevent="initBigBarChart(1)"
                      >
                        <span class="d-none d-md-block">
                          Arma vs Altre Forze
                        </span>
                        <span class="d-md-none">Altre</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 350px">
              <canvas :id="bigbarChartId.id"></canvas>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- End Charts-->
  </div>
</template>
<script>
// Charts
import { gauge, line, bar } from '../components/Charts/Chart';
import { Chart, registerables } from 'chart.js';
import data from '../store/data';
import Search2 from '../components/Searchbars/Search2.vue';

Chart.register(...registerables);

let chart1, chart2, chart3, chart4, chart5, chart6;

export default {
  name: 'Dashboard2',
  components: {
    Search2
  },
  data() {
    return {
      data: data.state.dashboard2,
      awarenessChartID: 'awarenessChart',
      competitiveChartID: 'competitiveChart',
      sentimentChartID: 'sentimentChart',
      sentimentVersusChartID: 'sentimentVersusChart',
      biglineChartId: {
        activeIndex: 0,
        id: 'biglineChart'
      },
      bigbarChartId: {
        activeIndex: 0,
        id: 'bigbarChart'
      }
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.drawGraphs();
      }
    }
  },
  methods: {
    formatBigNumber(numberString) {
      if (!numberString) return '';
      return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    drawGraphs() {
      this.initBigLineChart(0);
      this.initBigBarChart(0);
      chart1?.destroy();
      chart1 = gauge.createChart(
        this.awarenessChartID,
        this.data['Consapevolezza Brand']
      );
      chart2?.destroy();
      chart2 = gauge.createChart(
        this.competitiveChartID,
        this.data['Competitive Awareness']
      );
      chart3?.destroy();
      chart3 = gauge.createChart(this.sentimentChartID, this.data.SentimentCC);
      chart4?.destroy();
      chart4 = gauge.createChart(
        this.sentimentVersusChartID,
        this.data.SentimentVersus
      );
    },
    initBigLineChart(index) {
      chart5?.destroy();
      let dataFiltered = {};
      if (index === 0) dataFiltered = this.data.dates['Competitive Awareness'];
      else if (index === 1)
        dataFiltered = this.data.dates['Consapevolezza Brand'];
      chart5 = line.createChart(this.biglineChartId.id, dataFiltered);
      this.biglineChartId.activeIndex = index;
    },
    initBigBarChart(index) {
      chart6?.destroy();
      chart6 = bar.createChart(this.bigbarChartId.id, this.data.dates, index);
      this.bigbarChartId.activeIndex = index;
    }
  }
};
</script>
<style></style>
