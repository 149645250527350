<template>
  <div>
    <!--Searchbar-->
    <Search3 />
    <base-header type="dark" class="pb-6 pb-8 pt-5"></base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5">
          <card header-classes="bg-transparent">
            <div class="row">
              <div class="col-xl-12 mb-5">
                <card type="default" header-classes="bg-transparent">
                  <template v-slot:header>
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="h3 text-white mb-0">
                          Andamento Temporale
                          <div class="customtooltip">
                            <i class="fa fa-info"></i>
                            <span class="customtooltiptext">
                              Indica il numero degli eventi accaduti nel tempo
                              per la categoria, luogo e periodo selezionato
                            </span>
                          </div>
                        </h5>
                      </div>
                    </div>
                  </template>
                  <div class="chart-area" style="height: 350px">
                    <canvas :id="biglineChartId"></canvas>
                  </div>
                </card>
              </div>

              <div class="col-xl-12 mb-5">
                <card type="default" header-classes="bg-transparent">
                  <template v-slot:header>
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="h3 text-white mb-0">
                          Scenari Futuri
                          <div class="customtooltip">
                            <i class="fa fa-info"></i>
                            <span class="customtooltiptext">
                              Indica la possibile evoluzione nella settimana
                              successiva del numero degli eventi per la
                              categoria e il luogo selezionato. Sono previsti
                              tre casi: lo scenario migliore, quello medio e
                              quello peggiore
                            </span>
                          </div>
                        </h5>
                      </div>
                    </div>
                  </template>
                  <div class="chart-area" style="height: 350px">
                    <canvas :id="bandlineChartId"></canvas>
                  </div>
                </card>
              </div>

              <div class="col-xl-12">
                <card
                  type="default"
                  header-classes="bg-white"
                  body-classes="bg-white"
                >
                  <template v-slot:header>
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="h3 mb-0">
                          Alert nelle ultime 2 Settimane
                          <div class="customtooltip">
                            <i class="fa fa-info"></i>
                            <span class="customtooltiptext">
                              Segnala quando il numero dei reati per una
                              determinata categoria o una località sta crescendo
                              oltre le normali oscillazioni rivelando quindi
                              situazioni che potrebbero diventare critiche.
                            </span>
                          </div>
                        </h5>
                      </div>
                    </div>
                  </template>
                  <table class="table tablesorter">
                    <thead class="bg-default" style="color: white">
                      <tr>
                        <slot name="columns">
                          <th>Data</th>
                          <th>Numero</th>
                        </slot>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in Object.entries(
                          data.dashboard3_3.alerts
                        )"
                        :key="index"
                      >
                        <td>{{ value[0] }}</td>
                        <td>{{ value[1] }}</td>
                      </tr>
                    </tbody>
                  </table></card
                >
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- End Charts-->
  </div>
</template>
<script>
// Charts
import { line, band } from '../components/Charts/Chart';
import { Chart, registerables } from 'chart.js';
import data from '../store/data';
import Search3 from '../components/Searchbars/Search3.vue';

Chart.register(...registerables);

let chart1, chart2;

export default {
  name: 'Dashboard3',
  components: {
    Search3
  },
  data() {
    return {
      data: data.state.dashboard3,
      biglineChartId: 'biglineChart',
      bandlineChartId: 'bandlineChart'
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.drawGraphs();
      }
    }
  },
  methods: {
    drawGraphs() {
      this.initBigLineChart();
      this.initBandLineChart();
    },
    initBigLineChart() {
      chart1?.destroy();
      chart1 = line.createChart(
        this.biglineChartId,
        this.data.dashboard3_1.training.score,
        this.data.dashboard3_1.tooltip
      );
    },
    initBandLineChart() {
      chart2?.destroy();
      chart2 = band.createChart(
        this.bandlineChartId,
        this.data.dashboard3_2.predictions
      );
    }
  }
};
</script>
<style></style>
