<template>
  <div>
    <!--Searchbar-->
    <Search1 />

    <!--Cards-->
    <base-header type="dark" class="pb-6 pb-8 pt-5">
      <div class="row">
        <div class="col-xl-4 col-sm-12">
          <stats-card
            title="Eventi"
            type="gradient-red"
            :sub-title="formatBigNumber(this.data.counters.n_events.toString())"
            icon="ni ni-square-pin"
            class="mb-4 mb-xl-0"
            toolTip="Numero degli eventi registrati per la categoria, luogo e periodo selezionato"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-sm-12">
          <stats-card
            title="Eventi Attesi"
            type="gradient-green"
            :sub-title="
              formatBigNumber(this.data.counters.n_events_expected.toString())
            "
            icon="ni ni-bulb-61"
            class="mb-4 mb-xl-0"
            toolTip="Numero degli eventi che era lecito attendersi per la categoria, luogo e periodo selezionato se il rischio fosse uguale su tutto il territorio nazionale"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-sm-12">
          <stats-card
            title="Articoli"
            type="gradient-orange"
            :sub-title="
              formatBigNumber(this.data.counters.n_articles.toString())
            "
            icon="ni ni-single-copy-04"
            class="mb-4 mb-xl-0"
            toolTip="Numero degli articoli/notizie/commenti che hanno parlato degli eventi registrati per la categoria, luogo e periodo selezionato"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
    <!-- End Cards-->

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-6 mb-5">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">Indice</h6>
                  <h5 class="h3 mb-0">
                    Sicurezza Reale
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext">
                        Relazione tra eventi accaduti e quelli attesi per la
                        categoria, luogo e periodo selezionato. Varia tra -1 ed
                        1. Un valore di -1 indica un’elevata insicurezza, mentre
                        il valore +1 indica che il numero di reati accaduti è
                        pari a quelli attesi, e che quindi la sicurezza in
                        quell’area, periodo e categoria considerata è pari a
                        quella media italiana
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="realIndexChartID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6 mb-5">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">Indice</h6>
                  <h5 class="h3 mb-0">
                    Sicurezza Percepita
                    <div class="customtooltip">
                      <i class="fa fa-info"></i>
                      <span class="customtooltiptext">
                        Misura il “rumore” che si genera attorno ai singoli
                        reati nel periodo, luogo e categoria prescelta,
                        valutando quindi l’allarme sociale generato dai reati
                        considerati. Varia tra -1 ed 1. Il valore di +1 indica
                        che quel reato o quella tipologia di reati è avvertita
                        poco. Al contrario, un valore prossimo a -1 indica che i
                        reati di quella categoria di eventi sono stati
                        raccontati più e più volte, generando allarme sociale
                      </span>
                    </div>
                  </h5>
                </div>
              </div>
            </template>
            <div class="chart-area" style="height: 150px">
              <canvas :id="perceivedIndexChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- End Charts-->

    <!--Map-->
    <div class="container-fluid mt--15 mb-5">
      <div class="row">
        <div class="col">
          <card class="card-map" header-classes="bg-transparent">
            <template v-slot:header>
              <a class="row align-items-center" href="#map">
                <div class="col">
                  <h5 class="h3 mb-0">Mappa</h5>
                </div>
              </a>
            </template>
            <div>
              <Map />
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- End Map-->
  </div>
</template>
<script>
// Charts
import { Chart, registerables } from 'chart.js';
import data from '../store/data';
// Map
import Map from './Map.vue';
import { gauge } from '../components/Charts/Chart';
import Search1 from '../components/Searchbars/Search1.vue';

Chart.register(...registerables);

let chart1, chart2;

export default {
  name: 'Dashboard1',
  components: {
    Map,
    Search1
  },
  data() {
    return {
      data: data.state.dashboard1,
      realIndexChartID: 'realIndexChart',
      perceivedIndexChartID: 'perceivedIndexChart'
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.drawGraphs();
      }
    }
  },
  methods: {
    formatBigNumber(numberString) {
      if (!numberString) return '';
      return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    drawGraphs() {
      chart1?.destroy();
      chart1 = gauge.createChart(this.realIndexChartID, this.data.indexReal);
      chart2?.destroy();
      chart2 = gauge.createChart(
        this.perceivedIndexChartID,
        this.data.indexPerceived
      );
    }
  }
};
</script>
<style>
.card-map .card-body {
  padding: 0;
}
</style>
